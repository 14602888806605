import styled, { keyframes } from "styled-components"
import chroma from "chroma-js"
import { containerStyling } from "./utils"

// TODO: Focus / focus-visible styling
const ButtonBase = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  line-height: 1;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  display: inline-block; /* If we display btn as a link <a> this is helpful */
  text-decoration: none; /* If we display btn as a link <a> this is helpful */
  :disabled {
    cursor: not-allowed;
  }
  @media (hover: hover) {
    :hover {
      text-decoration: none; /* If we display btn as a link <a> this is helpful */
    }
  }
`
export const ButtonPrimary = styled(ButtonBase)`
  color: #fff;
  ${({ theme, status }) => {
    const accent = theme.colors.accent
    const [l, c, h] = chroma(accent).lch()
    const hueShift = chroma.lch(l - 20, c, h + 15).css()
    switch (status) {
      case "gray":
        return `
          background: #efefef;
          color: #666;
          box-shadow: none;
        `
      case "error":
        return `background: ${theme.colors.error};`
      case "darker":
        return `background: ${hueShift};`
      default:
        return `background: ${theme.colors.accent};`
    }
  }}
  padding: 0.85em 1.75em;
  ${({ noShadow }) => (noShadow ? "box-shadow: none;" : "")}
  border-radius: 300px; /* absurdly large, but could make bigger */
  font-weight: bold;
  transition: 0.2s;
  :hover,
  &.loading {
    color: #fff;
    ${({ theme, status }) => {
      switch (status) {
        case "gray":
          return `
            background: #ccc;
            color: #000;
            box-shadow: none;
          `
        case "error":
          return `background: ${chroma(theme.colors.error).darken(0.6)};`
        case "darker": {
          // Not really used anymore... can delete
          const accent = theme.colors.accent
          const [l, c, h] = chroma(accent).lch()
          const hueShift = chroma.lch(l - 20, c, h + 15).css()
          const [dl, dc, dh] = chroma(hueShift).lch()
          const darkerHover =
            l < 50
              ? chroma.lch(dl + 30, dc, dh).css()
              : chroma.lch(dl - 30, dc, dh).css()
          return `background: ${darkerHover};`
        }
        default: {
          const accent = theme.colors.accent
          const [l, c, h] = chroma(accent).lch()
          const accentHover =
            l < 50
              ? chroma.lch(l + 30, c, h).css()
              : chroma.lch(l - 30, c, h).css()
          return `background: ${accentHover};`
        }
      }
    }}
  }
  &.loading {
    /* Like disabled */
    pointer-events: none;
    cursor: not-allowed;
  }
`

// Button Gray Hover
// Very simple + subtle bold black text with gray background hover, fades in on mount
export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  } 
`
export const ButtonGrayHover = styled(ButtonBase)`
  color: #222;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.9rem;
  margin-left: 0.75em;
  padding: 0 0.9em;
  cursor: pointer;
  transition: 0.2s;
  animation: 0.2s ${fadeIn};
  border-radius: 100px;
  :hover,
  :focus {
    background: rgba(0, 0, 0, 0.075);
  }
`

export const ButtonSupport = styled(ButtonBase)`
  ${containerStyling}
  transition: 0.2s ease-in-out;
  border: 4px solid transparent;
  border-radius: 22px;
  font-size: 0.95rem;
  padding: 0.55em 0em;
  line-height: 1;
  box-shadow: 0 0 0 1px #ddd, 0px 1px 2px rgba(0, 0, 0, 0.3);
  @media (hover: hover) {
    :hover {
      border: 4px solid ${({ theme }) => chroma(theme.colors.accent).alpha(0.3)};
      box-shadow: 0 0 0 1px
          ${({ theme }) => chroma(theme.colors.accent).alpha(0.3)},
        0px 1px 2px ${({ theme }) => chroma(theme.colors.accent).alpha(0.3)};
    }
  }

  &.active {
    /* Need to make sure active overrides hover */
    color: ${({ theme }) =>
      chroma(theme.colors.accent).luminance(0.15)} !important;
    border: 4px solid ${({ theme }) => chroma(theme.colors.accent)} !important;
    box-shadow: 0 0 0 1px ${({ theme }) => chroma(theme.colors.accent)},
      0px 1px 2px ${({ theme }) => chroma(theme.colors.accent)} !important;
  }
  &.error {
    color: #222 !important;
    border: 4px solid ${({ theme }) => chroma(theme.colors.error)} !important;
    box-shadow: 0 0 0 1px ${({ theme }) => chroma(theme.colors.error)},
      0px 1px 2px ${({ theme }) => chroma(theme.colors.error)} !important;
  }
  :focus {
    outline: none;
  }
  :focus-visible {
    border: 4px solid #000 !important;
  }
`

export const ButtonLinks = styled(ButtonBase)`
  position: relative;
  text-align: center;
  ${containerStyling}
  padding: 1.15rem 3em 1.15rem 3em;
  transition: 0.2s ease-in-out;
  display: block;
  border-radius: 58px;
  line-height: 1.4;
  cursor: pointer;
  :after {
    font-size: 1.5em;
    line-height: 1.5em;
    content: "→";
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    text-align: center;
    /* background: red; */
    position: absolute;
    right: 0.25em;
    top: 50%;
    margin-top: -0.05em;
    transform: translateY(-50%);
  }
  @media (max-width: 600px) {
    font-size: 0.95rem;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  @media (hover: hover) {
    :hover {
      color: var(--accent-darker);
      box-shadow: 0 0 0 4px
        ${({ theme }) => chroma(theme.colors.accent).alpha(0.8)} !important;
    }
  }
`

export const ButtonSocial = styled(ButtonBase)`
  border-radius: 200px;
  background: #fff;
  color: #444;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0 0 0 5px transparent;
  border: none;
  transition: 0.2s ease-in-out;
  transform: translateZ(0);
  @media (hover: hover) {
    :hover {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2),
        0 0 0 4px ${({ theme }) => chroma(theme.colors.accent).luminance(0.8)};
      transform: scale(1.125) translateZ(0);
    }
  }
`
// For select box in product page
export const ButtonSuperSimple = styled(ButtonBase)`
  border-radius: 200px;
  background: #fff;
  color: #444;
  border: 1px solid #ccc;
  transition: 0.2s ease-in-out;
  transform: translateZ(0);
  padding: 0.8em 1.6em;
  * {
    cursor: pointer !important;
  }
  cursor: pointer !important;
`

export const ButtonGray = styled(ButtonPrimary).attrs((props) => ({
  // Static props
  status: "gray",
}))``

// Gray outline
export const ButtonGrayOutline = styled(ButtonPrimary)`
  background: #fff;
  color: #444;
  box-shadow: none;
  line-height: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  @media (hover: hover) {
    :hover {
      background: #fff;
      color: #000;
      border: 1px solid #000;
      outline: none;
      box-shadow: none;
    }
  }
  :focus {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    outline: none;
    box-shadow: none;
  }
`

// Mostly like a link
export const ButtonAccent = styled(ButtonBase)`
  color: ${({ theme }) => chroma(theme.colors.accent)};
  @media (hover: hover) {
    :hover {
      color: ${({ theme }) => chroma(theme.colors.accent)}; // TODO: make darker
    }
  }
  :focus {
    color: ${({ theme }) => chroma(theme.colors.accent)}; // TODO: make darker
  }
`
