import React, { useState } from "react"
import ReactDOM from "react-dom"
import useIsBrowser from "../hooks/useIsBrowser"
import { usePopper } from "react-popper"

const Popover = ({ target, setShow, show, children }) => {
  const isBrowser = useIsBrowser()
  const [arrowElement, setArrowElement] = useState(null)
  const [popperElement, setPopperElement] = React.useState(null)
  const { styles, attributes } = usePopper(target, popperElement, {
    modifiers: [
      { name: "arrow", options: { element: arrowElement, placement: "top" } },
    ],
  })

  const arrowHeight = 8
  const arrowWidth = 18
  const placement = attributes?.popper?.["data-popper-placement"]
  if (!isBrowser) return null

  return (
    <>
      {show &&
        ReactDOM.createPortal(
          <div
            role={"tooltip"}
            ref={setPopperElement}
            style={{
              ...styles.popper,
              zIndex: 100000,
              ...(placement === "bottom" && { paddingTop: arrowHeight }),
              ...(placement === "top" && { paddingBottom: arrowHeight }),
              filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
            }}
            onClick={() => setShow(false)} // for now ok if it goes away on click, future may want to stick around... especially with hover helper
            onMouseLeave={() => setShow(false)}
            onMouseEnter={() => setShow(true)}
            {...attributes.popper}
          >
            {/* Hover helper... so when mouse goes from btn to drop down it drop down sticks around */}
            {/* Possibly do in another way in the future ie: with timeouts etc */}
            <div
              style={{
                position: "absolute",
                height: 15,
                // background: "green",
                width: "100%",
                left: "0",
                ...(placement === "top" ? { top: "100%" } : { bottom: "100%" }),
              }}
            />
            <div
              ref={setArrowElement}
              style={{
                ...styles.arrow,
                width: arrowWidth,
                height: arrowHeight,
                ...(placement === "bottom" && {
                  top: 0,
                  clipPath: "polygon(0 100%, 50% 0%, 100% 100%)",
                }),
                ...(placement === "top" && {
                  bottom: 0,
                  clipPath: "polygon(0 0%, 50% 100%, 100% 0%)",
                }),
                background: "#fff",
                position: "absolute",
              }}
            />
            <div
              style={{
                background: "#fff",
                borderRadius: "15px",
              }}
            >
              {children}
            </div>
          </div>,
          document.body
        )}
    </>
  )
}

export default Popover
