import React, { useEffect, useState, useContext, createContext } from "react"
import { loadStripe } from "@stripe/stripe-js"
import axios from "axios"
import useTenantInfoContext from "./useTenantInfoContext"

// Local storage
export const getCartLocalStorageKey = (tenantId) => `${tenantId}-cart`
export const getCartSessionLocalStorageKey = (tenantId) => `${tenantId}-session`

// Context
export const CartContext = createContext(null)
export const CartProvider = ({ children }) => {
  const { tenantId } = useTenantInfoContext()
  const [cart, setCart] = useState(null)
  useEffect(() => {
    const key = getCartLocalStorageKey(tenantId)
    const res = JSON.parse(localStorage.getItem(key) || "[]")
    if (res) setCart(res)
  }, [tenantId, setCart])
  const loading = cart === null
  return (
    <CartContext.Provider value={[cart || [], setCart, loading]}>
      {children}
    </CartContext.Provider>
  )
}
export const useCartContext = () => {
  const context = useContext(CartContext)
  if (context === undefined) {
    // Let's yell at ourselves to make sure we use our Provider wrapper
    throw new Error(
      "Oooops, I'm guessing your forgot to use the Provider for this context"
    )
  }
  return context || []
}

// Some helper funcs
const getNextCartOnAdd = ({ items, cart, tenantInfo }) => {
  const { tenantId, tenantPaymentAccounts, tenantUrl, tenantName } = tenantInfo
  let nextCart = [...cart]
  items.forEach((item) => {
    const {
      product: { id },
      quantity,
    } = item
    const index = nextCart.findIndex(({ product: { id: cartId } }) => {
      return cartId === id
    })
    // Combine like quantities first
    if (index > -1) nextCart[index].quantity += quantity
    else {
      // Not in cart... add
      nextCart.push({
        ...item,
        product: {
          ...item.product,
          // - Also add tenant info while we're here so we don't have to via the comps
          tenantName,
          tenantId,
          tenantUrl,
          tenantPaymentAccounts,
        },
      })
    }
  })
  return nextCart
}
const getNextCartOnQuantityUpdate = ({ items, cart }) => {
  let nextCart = [...cart]
  items.forEach((item) => {
    const {
      product: { id },
      quantity,
    } = item
    const index = nextCart.findIndex(({ product: { id: cartId } }) => {
      return cartId === id
    })
    // Add like quantities first
    if (index > -1) {
      if (quantity === 0) {
        // Remove item
        nextCart = [...nextCart.slice(0, index), ...nextCart.slice(index + 1)]
      } else {
        // Update qty
        nextCart[index].quantity = quantity
      }
    }
  })
  return nextCart
}

// Stripe
let stripePromise
const redirectToStripe = async ({ sessionId, onError }) => {
  // Get Stripe.js instance
  const stripe = await stripePromise
  const result = await stripe.redirectToCheckout({
    sessionId,
  })

  if (result.error) {
    if (onError) onError()
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
  }
}

// TODO: Make own file
export const useCartClear = () => {
  const tenantInfo = useTenantInfoContext()
  const { tenantId } = tenantInfo
  const [, setCart] = useCartContext()
  const key = getCartLocalStorageKey(tenantId)
  const clearCart = () => {
    const sessionKey = getCartSessionLocalStorageKey(tenantId)
    localStorage.setItem(key, JSON.stringify([]))
    localStorage.removeItem(sessionKey)
    setCart([])
  }
  return clearCart
}

// The main event / hook...
// TODO: Break into smaller hooks
const useCartPrePayment = () => {
  const tenantInfo = useTenantInfoContext()
  const { tenantId, tenantPaymentAccounts } = tenantInfo
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [cart = [], setCart] = useCartContext() || []

  const key = getCartLocalStorageKey(tenantId)
  const addToCart = (items = []) => {
    const nextCart = getNextCartOnAdd({ items, cart, tenantInfo })
    localStorage.setItem(key, JSON.stringify(nextCart))
    setCart(nextCart)
  }
  const updateQuantities = (items = []) => {
    const nextCart = getNextCartOnQuantityUpdate({ items, cart })
    localStorage.setItem(key, JSON.stringify(nextCart))
    setCart(nextCart)
  }

  // Handle CART checkout instead of just product
  const onCheckout = async (email) => {
    const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
    const apiCode = process.env.GATSBY_SPARKLE_API_CODE
    setIsProcessing(true)

    // 1.) Load stripe promise
    if (tenantPaymentAccounts) {
      const { id: stripeAccount } =
        tenantPaymentAccounts.find(({ isDefault }) => isDefault === true) || {}
      stripePromise = loadStripe(
        `${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`,
        {
          stripeAccount,
        }
      )
    }

    // 2.) Get checkout session url for redirect
    const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
    api
      .post(`CreateStripeCheckoutSession?code=${apiCode}&v=2`, {
        tenantId,
        productLineItems: cart,
        customerEmail: email,
      })
      .then((response) => {
        const { data: { id: sessionId } = {} } = response || {}
        // Save sessionId to local storage so we can clear out cart later (on success)
        localStorage.setItem(getCartSessionLocalStorageKey(tenantId), sessionId)
        redirectToStripe({ sessionId, onError: () => setError(true) })
      })
      .catch((error) => {
        console.log(error)
        setIsProcessing(false)
        setError(true) // TODO: Show something on error
      })
      .finally(() => {})
  }

  // TODO: Make this an object instead
  // TODO: Break apart into a couple of hooks
  return [
    isProcessing,
    onCheckout,
    cart,
    addToCart,
    updateQuantities,
    tenantInfo,
    error,
  ]
}

export default useCartPrePayment
