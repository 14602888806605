import { createGlobalStyle } from "styled-components"
import chroma from "chroma-js"

const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
  }
  h1, h2,h3,h4, h5 ,h6 {
    font-weight: normal;
  }
  html {
    overscroll-behavior-x: none;
    overflow-x: hidden;
    height: 100%;
    background: #fff;
  }
  html, body {
    overscroll-behavior-y: none;
  }
  body,
  html, h1, h2, h3, h4, h5, h6, p, select, input, textarea {
    font-family: Montserrat, sans-serif;
    color: #222; /* Normal color should turn into a token */
  }
  #___gatsby, #gatsby-focus-wrapper {
    background: #fff;
  }
  a {
    text-decoration: none;
  }
  /* Button reset */
  button {
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    background-color: transparent;
    cursor: pointer;
  }
  input, textarea {
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
  }
  /* Remove tap highlight */
  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  /* Helpers */
  .pos-full {
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    position: absolute !important;
  }
  .pos-center {
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  .pos-center-full {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  /* Color helper classes - maybe just go with color tokens */
  .error-text {
    color: ${({ theme }) => theme.colors.error};
  }
  .error-fill {
    fill: ${({ theme }) => theme.colors.error};
  }
  .accent-fill {
    fill: ${({ theme }) => theme.colors.accent};
  }
  .wesparkle-fill {
    fill: ${({ theme }) => theme.colors.sparkleAccent};
  }

  /* Color tokens + nums */
  :root {
    --content-pad: 1.25rem;
    --success: #119862; /* TODO: possibly add to theme */
    --error: ${({ theme }) => theme.colors.error};

    --wesparkle-red: ${({ theme }) => theme.colors.sparkleAccent};
    --wesparkle-darkblue: #07234C;
    --wesparkle-green: #06D6A0;
    --wesparkle-blue: #118AB2;
    --wesparkle-lightblue: #E3EFF3;
    --wesparkle-yellow: #FFDD66;

    --accent: ${({ theme }) => theme.colors.accent};
    --accent-darker: ${({ theme }) =>
      chroma(theme.colors.accent).luminance(0.15)};
    --accent-opacity-20: ${({ theme }) =>
      chroma(theme.colors.accent).alpha(0.2)}
  }
  @media screen and (max-width: 700px) {
    :root {
    --content-pad: 10px;
    }
  }
`

export default GlobalStyles
