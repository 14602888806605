import React from "react"
import IconLogo from "../../icons/IconLogo"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { Content } from "./Content"
import NavBarLogin from "./NavBarLogin"
import NavBarCart from "./NavBarCart"
import { useCartContext } from "../../hooks/useCartPrePayment"
import NavBarQuickLink from "./NavBarQuickLink"
import { ButtonGrayHover } from "../../core/Buttons"
import useIsBrowser from "../../hooks/useIsBrowser"
import useTenantSignedInContext from "../../hooks/useTenantSignedInContext"
import { segmentAction } from "../../hooks/useSegmentTrack"

const iconHeight = 30
const verticalPad = 12
const Header = styled.header`
  ${({ hasItemsInCart }) =>
    hasItemsInCart
      ? `
      z-index: 100;
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;`
      : `position: relative;`}
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  line-height: 0;
  background: #fff;
  height: ${verticalPad * 2 + iconHeight}px;
  padding-top: ${verticalPad}px;
  padding-bottom: ${verticalPad}px;
`

const NavBar = ({ showCart }) => {
  const location = useLocation()
  const { tenantSignedIn } = useTenantSignedInContext()
  const [cart = [], , loading] = useCartContext()
  const isSignedIn = tenantSignedIn?.tenantName
  const numCartItems = cart.reduce((a, { quantity = 0 }) => {
    return quantity + a
  }, 0)
  const hasItemsInCart = numCartItems > 0

  const showNav = (showCart && hasItemsInCart) || isSignedIn || process.env.GATSBY_HAS_SPRKL_CLAIM_SITE_FEATURE
  const isBrowser = useIsBrowser()

  // Don't show nav unless...
  // 1. Have items in cart
  // 2. Are logged in
  if (!showNav) return null

  // Claim site 
  const sparkleClaimPath = process.env.GATSBY_SPARKLE_CLAIM_SITE_PATH || process.env.GATSBY_SPARKLE_ADMIN_PATH;
  
  return (
    <>
      {/* Padding for sticky nav when there are items in cart */}
      {(hasItemsInCart) && (
        <div style={{ height: iconHeight + verticalPad * 2 }} />
      )}
      <Header hasItemsInCart={hasItemsInCart}>
        <Content
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", lineHeight: 0 }}>
            {isSignedIn && (
              <>
                <a
                  href={process.env.GATSBY_SPARKLE_PATH}
                  onClick={() => {
                    segmentAction.track("Sparkles Nav Logo Click")
                  }}
                >
                  <IconLogo
                    onlyMark
                    style={{ width: "auto", height: iconHeight }}
                    className={"wesparkle-fill"}
                  />
                </a>
                {/* To tenant's own site or to settings */}
                {isBrowser && (
                  <NavBarQuickLink style={{ height: iconHeight }} />
                )}
              </>
            )}
            {process.env.GATSBY_HAS_SPRKL_CLAIM_SITE_FEATURE && isBrowser && 
              <>
                <a
                  href="https://sprkl.es/"
                  onClick={() => {
                    segmentAction.track("Sparkles Nav Logo Click")
                  }}
                >
                <IconLogo
                  onlyMark
                  style={{ width: "auto", height: iconHeight }}
                  className={"wesparkle-fill"}
                />
              </a>
              <ButtonGrayHover
                  as={"a"}
                  onClick={() => {
                    segmentAction.track(
                      "Sparkles Nav To Claim Site Click",
                      {}
                    )
                  }}
                  href={`${sparkleClaimPath}?signUp=true&siteName=${
                    location?.pathname || ""
                  }&claimed=true`}
                  style={{ height: iconHeight }}
                >
                  {"Claim my site →"}
                </ButtonGrayHover>
              </>
            }
          </div>
          
          <div style={{ display: "flex" }}>
           
            {showCart && isBrowser && (
              <NavBarCart num={numCartItems} loading={loading} />
            )}
            {isBrowser && isSignedIn && (
              <div style={{ marginLeft: "1rem" }}>
                <NavBarLogin trackingPrefix={"Sparkles"} />
              </div>
            )}
          </div>
        </Content>
      </Header>
    </>
  )
}

export default NavBar
